import { fireAnalyticsEvent } from '../helpers/fireAnalyticsEvent'
import { fireHeapEvent } from '../helpers'
import eventNames from '../constants/navigation'
import eventNamesV3 from '../constants/navV3'

const {
  Global_MarketingNav_Clicked_DropdownLink,
  Global_MarketingNav_Clicked_NavLink,
  Global_MarketingNav_Clicked_SigninButton,
  Global_MarketingNav_Clicked_SignupButton,
  Global_MarketingNav_Clicked_DropdownMenu,
  Global_MarketingNav_Clicked_DashboardButton,
  Global_PlatformNav_Clicked_DropdownMenu,
  Global_PlatformNav_Clicked_NavLink,
  Global_PlatformNav_Clicked_DropdownMenuItem,
  Global_Navigation_Clicked_FlowcodeLogo,
  Global_Navigation_Clicked_HamburgerMenu,
  Global_Navigation_Closed_MobileNav
} = eventNames

const {
  // mobile
  Platform_MobileBottomNavBar_Clicked_Create_Fab,
  Platform_MobileBottomNavBar_Clicked_Tab,
  Platform_Mobile_AssetManagmentMenu_Clicked_Option,
  Platform_Mobile_AssetManagmentMenu_Dismissed_Sheet,
  Platform_Mobile_AssetManagmentMenu_Clicked_CreateFolder,
  Platform_Mobile_AssetManagmentMenu_Clicked_GetMoreStorage,
  Platform_TopPageNav_Clicked_Open_FileExplorerSheet,
  Platform_MobileMoreOptionsSheet_Clicked_Option,
  Platform_MobileMoreOptionsSheet_Opened_ProfileSection,
  Platform_MobileMoreOptionsSheet_Clicked_Upgrade,
  Platform_Mobile_AssetCreationMenu_Clicked_Option,
  Platform_MobileMoreOptionsSheet_ProfileMenu_Clicked_MenuItem,
  Platform_MobileMoreOptionsSheet_ProfileMenu_Clicked_EditProfilePicture,
  Platform_MobileMoreOptionsSheet_ProfileMenu_Clicked_Upgrade,
  Platform_MobileMoreOptionsSheet_ProfileMenu_Dismissed_Sheet,
  Platform_MobileMoreOptionsSheet_TopBar_Clicked_Item,
  Platform_MobilePageNav_Clicked_BackButton,

  // desktop
  Platform_DesktopNavigationRail_Clicked_MenuItem,
  Platform_Desktop_AssetManagmentMenu_Clicked_Option,
  Platform_Desktop_AssetManagmentMenu_Clicked_CreateFolder,
  Platform_Desktop_AssetManagmentMenu_Clicked_GetMoreStorage,
  Platform_Desktop_LeftSideDrawer_Clicked_ToggleOpen,
  Platform_Desktop_LeftSideDrawer_Hovered_OpenDrawer,
  Platform_Desktop_TopNav_Clicked_ProfilePicture,
  Platform_Desktop_TopNav_Clicked_IconButton,
  Platform_Desktop_TopNav_Clicked_Upgrade,
  Platform_Desktop_AssetCreationMenu_Clicked_Option,
  Platform_DesktopNavigationRail_Clicked_AssetCreation_Fab,
  Platform_Desktop_TopNav_ProfileMenu_Clicked_EditProfilePicture,
  Platform_Desktop_TopNav_ProfileMenu_Clicked_MenuItem,
  Platform_Desktop_TopNav_CartEmptyState_Clicked_VisitPrintStoreButton,

  // miscellaneous
  Platform_Flowteams_Clicked_TopActionButton,
  Platform_Flowteams_Clicked_FAQButton,
  Platform_Flowteams_PageHeader_Clicked_Tab,
  Platform_FeatureLandingPage_Clicked_Button,
  Platform_Creation_UnsavedWorkWarningModal_Clicked_Button,
  Platform_CodeCreation_Clicked_CancelButton,
  Platform_PageCreation_Clicked_CancelButton,
  Global_Navigation_MobileNav_Clicked_ConnectWithUs,
  Global_Navigation_MobileNav_Clicked_SignUp,
  Global_Navigation_MobileNav_Clicked_SignIn,
  Platform_Mobile_TopNav_Clicked_Edit_FolderName,
  Platform_Mobile_TopNav_Blurred_FolderNameInput,
  Platform_Mobile_TopNav_Clicked_SaveFolderName,
  Platform_ProfilePictureUpload_Clicked_Button,
  Flowcode_Clicked_KebabMenu,
  Flowcode_CompleteState_Clicked_Share,
  Platform_PlansUpgradeDialog_Clicked_PlanTile,
  Platform_PlansUpgradeDialog_Dismissed_Dialog,
  Platform_SideNav_Clicked_ExploreAPI,
  Platform_SideNav_Clicked_DismissDeveloperAPI
} = eventNamesV3

const marketingNavEvents = {
  Global_MarketingNav_Clicked_DropdownLink: (linkText: string) => {
    fireHeapEvent(Global_MarketingNav_Clicked_DropdownLink, { linkText })
  },
  Global_MarketingNav_Clicked_NavLink: (linkText: string) => {
    fireHeapEvent(Global_MarketingNav_Clicked_NavLink, { linkText })
  },
  Global_MarketingNav_Clicked_SigninButton: () => {
    fireHeapEvent(Global_MarketingNav_Clicked_SigninButton)
  },
  Global_MarketingNav_Clicked_SignupButton: () => {
    fireHeapEvent(Global_MarketingNav_Clicked_SignupButton)
  },
  Global_MarketingNav_Clicked_DropdownMenu: (menuTitle: string) => {
    fireHeapEvent(Global_MarketingNav_Clicked_DropdownMenu, { menuTitle })
  },
  Global_MarketingNav_Clicked_DashboardButton: () => {
    fireHeapEvent(Global_MarketingNav_Clicked_DashboardButton)
  }
}

const platformNavEvents = {
  Global_PlatformNav_Clicked_DropdownMenu: (menuTitle: string) => {
    fireHeapEvent(Global_PlatformNav_Clicked_DropdownMenu, { menuTitle })
  },
  Global_PlatformNav_Clicked_NavLink: (linkText: string) => {
    fireHeapEvent(Global_PlatformNav_Clicked_NavLink, { linkText })
  },
  Global_PlatformNav_Clicked_DropdownMenuItem: (itemText?: string) => {
    fireHeapEvent(Global_PlatformNav_Clicked_DropdownMenuItem, { itemText })
  },
  Global_Navigation_Clicked_DomainsLocked(): void {
    fireHeapEvent(eventNames.Global_Navigation_Clicked_DomainsLocked)
  }
}

const genericNavEvents = {
  Global_Navigation_Clicked_FlowcodeLogo: () => {
    fireHeapEvent(Global_Navigation_Clicked_FlowcodeLogo)
  },
  Global_Navigation_Clicked_HamburgerMenu: () => {
    fireHeapEvent(Global_Navigation_Clicked_HamburgerMenu)
  },
  Global_Navigation_Closed_MobileNav: () => {
    fireHeapEvent(Global_Navigation_Closed_MobileNav)
  },
  Global_Navigation_MobileNav_Clicked_ConnectWithUs: () => {
    fireHeapEvent(Global_Navigation_MobileNav_Clicked_ConnectWithUs)
  }
}

const mobileV3NavEvents = {
  Platform_MobileBottomNavBar_Clicked_Tab: (tabName: string) => {
    fireHeapEvent(Platform_MobileBottomNavBar_Clicked_Tab, { tabName })
  },
  Platform_MobileBottomNavBar_Clicked_Create_Fab: (
    exposedToLLM: boolean,
    llmLabelFlag?: string | null
  ) => {
    fireHeapEvent(Platform_MobileBottomNavBar_Clicked_Create_Fab, { exposedToLLM, llmLabelFlag })
  },
  Platform_Mobile_AssetManagmentMenu_Clicked_Option: (menuItem: string) => {
    fireHeapEvent(Platform_Mobile_AssetManagmentMenu_Clicked_Option, { menuItem })
  },
  Platform_Mobile_AssetManagmentMenu_Dismissed_Sheet: () => {
    fireHeapEvent(Platform_Mobile_AssetManagmentMenu_Dismissed_Sheet)
  },
  Platform_Mobile_AssetManagmentMenu_Clicked_CreateFolder: () => {
    fireHeapEvent(Platform_Mobile_AssetManagmentMenu_Clicked_CreateFolder)
  },
  Platform_Mobile_AssetManagmentMenu_Clicked_GetMoreStorage: () => {
    fireHeapEvent(Platform_Mobile_AssetManagmentMenu_Clicked_GetMoreStorage)
  },
  Platform_TopPageNav_Clicked_Open_FileExplorerSheet: () => {
    fireHeapEvent(Platform_TopPageNav_Clicked_Open_FileExplorerSheet)
  },
  Platform_MobileMoreOptionsSheet_Clicked_Option: (menuItem: string) => {
    fireHeapEvent(Platform_MobileMoreOptionsSheet_Clicked_Option, { menuItem })
  },
  Platform_MobileMoreOptionsSheet_Opened_ProfileSection: () => {
    fireHeapEvent(Platform_MobileMoreOptionsSheet_Opened_ProfileSection)
  },
  Platform_MobileMoreOptionsSheet_Clicked_Upgrade: () => {
    fireHeapEvent(Platform_MobileMoreOptionsSheet_Clicked_Upgrade)
  },
  Platform_Mobile_AssetCreationMenu_Clicked_Option: (
    menuItem: string,
    featureFlag?: string | null
  ) => {
    fireHeapEvent(Platform_Mobile_AssetCreationMenu_Clicked_Option, { menuItem, featureFlag })
  },
  Platform_MobileMoreOptionsSheet_ProfileMenu_Clicked_MenuItem: (menuItem: string) => {
    fireHeapEvent(Platform_MobileMoreOptionsSheet_ProfileMenu_Clicked_MenuItem, { menuItem })
  },
  Platform_MobileMoreOptionsSheet_ProfileMenu_Clicked_EditProfilePicture: () => {
    fireHeapEvent(Platform_MobileMoreOptionsSheet_ProfileMenu_Clicked_EditProfilePicture)
  },
  Platform_MobileMoreOptionsSheet_ProfileMenu_Clicked_Upgrade: () => {
    fireHeapEvent(Platform_MobileMoreOptionsSheet_ProfileMenu_Clicked_Upgrade)
  },
  Platform_MobileMoreOptionsSheet_ProfileMenu_Dismissed_Sheet: () => {
    fireHeapEvent(Platform_MobileMoreOptionsSheet_ProfileMenu_Dismissed_Sheet)
  },
  Platform_MobileMoreOptionsSheet_TopBar_Clicked_Item: (menuItem: string) => {
    fireHeapEvent(Platform_MobileMoreOptionsSheet_TopBar_Clicked_Item, { menuItem })
  },
  Platform_MobilePageNav_Clicked_BackButton: (pathname: string) => {
    fireHeapEvent(Platform_MobilePageNav_Clicked_BackButton, { pathname })
  },
  Flowcode_Clicked_KebabMenu: ({ flowcodeId }: { flowcodeId: string }) => {
    fireHeapEvent(Flowcode_Clicked_KebabMenu, { flowcodeId })
  },
  Flowcode_CompleteState_Clicked_Share: ({ flowcodeId }: { flowcodeId: string }) => {
    fireHeapEvent(Flowcode_CompleteState_Clicked_Share, { flowcodeId })
  }
}

export const desktopV3NavEvents = {
  Platform_DesktopNavigationRail_Clicked_MenuItem: (menuItem: string) => {
    fireHeapEvent(Platform_DesktopNavigationRail_Clicked_MenuItem, {
      menuItem
    })
  },
  Platform_Desktop_AssetManagmentMenu_Clicked_Option: (menuItem: string) => {
    fireHeapEvent(Platform_Desktop_AssetManagmentMenu_Clicked_Option, { menuItem })
  },
  Platform_Desktop_AssetManagmentMenu_Clicked_GetMoreStorage: () => {
    fireHeapEvent(Platform_Desktop_AssetManagmentMenu_Clicked_GetMoreStorage)
  },
  Platform_Desktop_AssetManagmentMenu_Clicked_CreateFolder: () => {
    fireHeapEvent(Platform_Desktop_AssetManagmentMenu_Clicked_CreateFolder)
  },
  Platform_Desktop_LeftSideDrawer_Clicked_ToggleOpen: (setOpen: boolean) => {
    fireHeapEvent(Platform_Desktop_LeftSideDrawer_Clicked_ToggleOpen, { setOpen })
  },
  Platform_Desktop_LeftSideDrawer_Hovered_OpenDrawer: () => {
    fireHeapEvent(Platform_Desktop_LeftSideDrawer_Hovered_OpenDrawer)
  },
  Platform_Desktop_TopNav_Clicked_ProfilePicture: () => {
    fireAnalyticsEvent(Platform_Desktop_TopNav_Clicked_ProfilePicture)
  },
  Platform_Desktop_TopNav_Clicked_IconButton: (menuItem: string) => {
    fireAnalyticsEvent(Platform_Desktop_TopNav_Clicked_IconButton, { menuItem })
  },
  Platform_Desktop_TopNav_Clicked_Upgrade: () => {
    fireAnalyticsEvent(Platform_Desktop_TopNav_Clicked_Upgrade)
  },
  Platform_Desktop_AssetCreationMenu_Clicked_Option: (
    menuItem: string,
    featureFlag?: string | null
  ) => {
    fireHeapEvent(Platform_Desktop_AssetCreationMenu_Clicked_Option, { menuItem, featureFlag })
  },
  Platform_DesktopNavigationRail_Clicked_AssetCreation_Fab: (
    settingDialogOpen: boolean,
    exposedToLLM: boolean,
    llmLabelFlag?: string | null
  ) => {
    fireHeapEvent(Platform_DesktopNavigationRail_Clicked_AssetCreation_Fab, {
      settingDialogOpen,
      exposedToLLM,
      llmLabelFlag
    })
  },
  Platform_Desktop_TopNav_ProfileMenu_Clicked_MenuItem: (menuItem: string) => {
    fireHeapEvent(Platform_Desktop_TopNav_ProfileMenu_Clicked_MenuItem, { menuItem })
  },
  Platform_Desktop_TopNav_ProfileMenu_Clicked_EditProfilePicture: () => {
    fireHeapEvent(Platform_Desktop_TopNav_ProfileMenu_Clicked_EditProfilePicture)
  },
  Platform_Desktop_TopNav_CartEmptyState_Clicked_VisitPrintStoreButton: () => {
    fireHeapEvent(Platform_Desktop_TopNav_CartEmptyState_Clicked_VisitPrintStoreButton)
  }
}

export const miscellaneousV3Events = {
  Platform_Flowteams_Clicked_TopActionButton: (action: string, pathname: string) => {
    fireHeapEvent(Platform_Flowteams_Clicked_TopActionButton, { action, pathname })
  },
  Platform_Flowteams_Clicked_FAQButton: (pathname: string) => {
    fireHeapEvent(Platform_Flowteams_Clicked_FAQButton, { pathname })
  },
  Platform_Creation_UnsavedWorkWarningModal_Clicked_Button: (
    codeOrPage: string,
    button: string
  ) => {
    fireHeapEvent(Platform_Creation_UnsavedWorkWarningModal_Clicked_Button, { codeOrPage, button })
  },
  Platform_Flowteams_PageHeader_Clicked_Tab: (tabName: string) => {
    fireHeapEvent(Platform_Flowteams_PageHeader_Clicked_Tab, { tabName })
  },
  Global_Navigation_MobileNav_Clicked_SignUp: () => {
    fireHeapEvent(Global_Navigation_MobileNav_Clicked_SignUp)
  },
  Global_Navigation_MobileNav_Clicked_SignIn: () => {
    fireHeapEvent(Global_Navigation_MobileNav_Clicked_SignIn)
  },
  Platform_FeatureLandingPage_Clicked_Button: (pathname: string, buttonCta: string) => {
    fireHeapEvent(Platform_FeatureLandingPage_Clicked_Button, { pathname, buttonCta })
  },
  Platform_CodeCreation_Clicked_CancelButton: () => {
    fireHeapEvent(Platform_CodeCreation_Clicked_CancelButton)
  },
  Platform_PageCreation_Clicked_CancelButton: () => {
    fireHeapEvent(Platform_PageCreation_Clicked_CancelButton)
  },
  Platform_Mobile_TopNav_Clicked_Edit_FolderName: () => {
    fireHeapEvent(Platform_Mobile_TopNav_Clicked_Edit_FolderName)
  },
  Platform_Mobile_TopNav_Blurred_FolderNameInput: () => {
    fireHeapEvent(Platform_Mobile_TopNav_Blurred_FolderNameInput)
  },
  Platform_Mobile_TopNav_Clicked_SaveFolderName: () => {
    fireHeapEvent(Platform_Mobile_TopNav_Clicked_SaveFolderName)
  },
  Platform_ProfilePictureUpload_Clicked_Button: (button: string) => {
    fireHeapEvent(Platform_ProfilePictureUpload_Clicked_Button, { button })
  },
  Platform_PlansUpgradeDialog_Clicked_PlanTile: (planType: string) => {
    fireHeapEvent(Platform_PlansUpgradeDialog_Clicked_PlanTile, { planType })
  },
  Platform_PlansUpgradeDialog_Dismissed_Dialog: () => {
    fireHeapEvent(Platform_PlansUpgradeDialog_Dismissed_Dialog)
  },
  Platform_SideNav_Clicked_ExploreAPI: () => {
    fireAnalyticsEvent(Platform_SideNav_Clicked_ExploreAPI)
  },
  Platform_SideNav_Clicked_DismissDeveloperAPI: () => {
    fireAnalyticsEvent(Platform_SideNav_Clicked_DismissDeveloperAPI)
  }
}

const e = {
  ...marketingNavEvents,
  ...platformNavEvents,
  ...genericNavEvents,
  ...mobileV3NavEvents,
  ...desktopV3NavEvents,
  ...miscellaneousV3Events
}

export default e
